.site-footer{
   background: $primary-dark;
}
.site-footer *{
  color:white !important;
}
.site-footer__section-title{
  font-weight: 700 !important;
  margin-bottom: 0.5rem;
}
.site-footer ul li a {
  font-weight: 500 !important;
  &:hover{
    opacity: 0.5;
  }
}

.site-footer .social-icon-link .icon{
  display: inline-block;
  width: 20px;
  height: 16px;
}
.site-footer .social-icon-link .icon svg{
    position: absolute;
    width: 20px;
}
