#testimonial-section{

}

.testimonial-item{
    margin-bottom:3rem;
}
.testimonial-item__body-wrap{
  padding:0 0 0 3rem;
  width: 60%;
}
.testimonial-item__photo-wrap{
  width: 30%;
}
.testimonial-item__body{
  font-weight: bold;
  font-style: italic;
  font-weight: 600;
  text-align: left;
}

.testimonial-item__photo {
  border: 10px white solid;
  box-shadow: $box-shadow-smooth;
  border-radius: 3px;
  transform: rotate(-8deg);
}

.testimonial-item__rating{
  background: white;
  text-align: center;
  color:$primary-dark;
  padding-top: 0.5rem;
}

.testimonial-item:nth-child(even) > div {
  flex-direction: row-reverse;
  .testimonial-item__body{
    text-align: right;
  }
  .testimonial-item__photo{
    transform: rotate(8deg);
  }
  .testimonial-item__body-wrap{
    padding:0 3rem 0 0;
  }
  .testimonial-item__signature{
    text-align: right;
  }
}

@include mobile{
  .testimonial-item__body-wrap{
    padding:0 !important;
    padding-top:2rem !important;
    width: 80%;
    margin:auto;
  }
  .testimonial-item__signature,
  .testimonial-item__body{
    text-align: center !important;
  }
  .testimonial-item__photo-wrap{
    width: 70%;
    margin:auto;
    margin-bottom:3rem;
  }
}