// IE FIX
.modal-content,
.modal-card{
  // Responsiveness
  @include tablet{
    margin: 0;
  }
}

.modal-content{
  border-radius: 1rem;
}
// ANIMATION WEIRDNESS
.modal.is-active{
  @include anim(0s,0.25s,fadeIn);
}
.modal.is-active
.modal-content{
  @include anim(0s,0.5s,jumpUp);
}

@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes jumpUp {
  0%{
    opacity: 0;
    transform:translate(0,50px);
    transform: rotateX(180deg);
  }
  25%{
    opacity: 1;
  }
  75%{
    transform: translate(0,-10px);
  }
  100%{
    transform: translate(0,0);
    transform: rotateX(0deg);
  }
}

.modal-close{
  background:$siteRed;
}