@import "variables";
@import "~bulma";
@import "~swiper/dist/css/swiper.min.css";
@import "components/flatpicker";
@import "components/fullscreen-module";
@import "~slick-carousel/slick/slick.scss";
//@import "~nouislider/distribute/nouislider.css";
@import "vendors/noUiSlider";
@import "vendors/noUiSlider";

html, body {
  background: $body-bg;
  height: 100%;
}

@import "mixins";
@import "components/main-nav";
@import "components/mls-error";
@import "listing/side-bar";
@import "listing/listing-summary";
@import "listing/slide-show";
@import "listing/details";
@import "listing/mobile-info";
@import "components/listing-card";
@import "components/listing-search";
@import "components/listing-search-mobile";
@import "components/modals";
@import "components/visit-request-form";
@import "components/immo-alert";
@import "components/sold-card";
@import "about/about";
@import "about/awards";
@import "about/testimonials";
@import "contact/contact";
@import "portfolio/portfolio";
@import "home/home";
@import "vendor-services/vendor-services";
@import "partials/testimonial-promo";
@import "partials/site-footer";
@import "hood/hood";
@import "services/services";

.title, .slab {
  font-family: 'Roboto Slab', serif;
  font-weight: 100;
  color: $primary-dark;
}

.squared {
  border-radius: 0px;
}

.no-shadow {
  box-shadow: none;
}

.listing-detail__right-column {
  position: relative;
  > div.fixed-wrapper {
    position: fixed;
  }
}

.js-sticky {
  transition: all ease 0.5s;
}

.offset {
  color: lighten(black, 50%);
}

.listing-result-card {
  box-shadow: $box-shadow;
  > .card-content {
    border-top: 0.25rem solid $primary;
  }
}

.button {
  box-shadow: $box-shadow;
  text-shadow: $box-shadow;
  font-weight: 500;
}

.is-primary-dark {
  background: $primary-dark;
  border: $primary-dark;
  color: white;
}

.max-500 {
  max-width: 500px;
}

.js-listing-multi-tab-contents > section {
  transition: 0.5s all ease;
  opacity: 1;
  height: auto;
  max-height: 2000px;
  overflow: hidden;
}

.hide-tab {
  opacity: 1;
  max-height: 0px !important;
  overflow: hidden;
}

.control-bottom {
  margin-bottom: 0.75rem;
}

.control-bottom-lg {
  margin-bottom: 1.5rem;
}

.js-truncate {
  max-height: 100px;
  transition: all ease 0.5s;
}

.js-truncate.active {
  max-height: 5000px;
}

.truncate-button {
  color: $primary-dark;
  opacity: 0.5;
  i {
    font-size: 1.5rem;
    color: $primary-dark;
  }
  transition: all ease 0.25s;
  &:hover {
    opacity: 1;
  }
}

.occupation-detail {
  .js-more-info {
    margin-bottom: 1.5rem;
  }
  .icon {
    color: $primary;
  }
  .slab {
    margin-top: 1.5rem;
  }
}

.occupation-detail .title strong {
  font-weight: 700;
}

strong {
  font-weight: 700 !important;
}

.control-top {
  margin-top: 1.5rem;
}

.inclusions i {
  color: $primary;
}

.tile.is-danger {
  background: $danger;
}

// tabs stuff
.tabs li.is-active a {
  border-bottom-color: $primary-dark;
  border-bottom: 2px solid $primary-dark;
  color: $primary-dark;
  font-weight: 700;
}

.tabs a {
  border-bottom: $primary-dark 1px solid;
}

.tabs a:hover {
  border-bottom-color: $primary-dark;
  color: $primary;
}

// modifier class for offset background
.section.is-offset {
  background: #F2F2F2;
}

.is-bolder {
  font-weight: bolder !important;
}

// news pop up
.news-modal {
  .modal-content {
    .content{
      padding:1em;
      margin-bottom:0;
    }
    footer.content{
      padding-top:0;
    }
    background: $body-bg;
    max-height: none;
  }
}