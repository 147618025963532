.service-promo{
  background: $primary-dark;
  padding:5rem;

  @include mobile(){
    padding: 1.25rem;
  }
}
.service-promo {
  .title,.subtitle,strong,p{
    color:white;
  }
  .title{
    @include mobile() {
        font-size: 1.5rem;
    }
  }
}