.visit-request__wrapper{
  background: $primary-dark;
}
.visit-request__title{
  font-size: 1.5rem;
  color:white !important;
  font-family:$slab-font;
  font-weight: 500;
  margin-bottom:0rem !important;
  @include mobile(){
    font-size:1rem;
  }
}
.visit-request__address{
  color:white;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
  text-align:center;
}
.visit-request-times-form{
  display:none;
  color:white;
  .title{
    color: white;
  }
}
.visit-request-times-form.is-active{
  display:block;
  @include anim(0,1s,jumpUp)
}

.fadeOut{
  @include anim(0,0.5s,fadeOutUp);
}

@keyframes fadeOutUp {
  0%{
    opacity:1;
  }
  90%{
    opacity:0;
    transform:translate(0px,50px);
    height: auto;
  }
  100%{
    height:0px;
    overflow: hidden;
  }
}

.visit-request-thank-you{
  display: none;
  color:white;
  text-align: center;
  .title{
    color: white;
  }
}

.js-final-button{
  margin-top: 0.75em;
}