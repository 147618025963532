.immo-alert{
  background: $primary-dark;
  padding: 1.25rem;
  color:white;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.immo-alert label {
  margin-right: 0.5rem;
  &:hover{
    color:white;
    opacity: 0.75;
  }
}