
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; /* Fix 401 */
}
.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: $primary-dark;
}
.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 25px;
}
.noUi-horizontal .noUi-handle {
  width: 24px;
  height: 20px;
  left: -20px;
  top: -10px;
  border-radius:15px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

/* Styling;
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  //border: 1px solid #D3D3D3;
  //box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-connect {
  background: $primary-dark;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}
.noUi-handle {
  //border: 1px solid #D9D9D9;
  border-radius: 15px;
  background: $primary-dark;
  cursor: default;
  border:2px $primary solid;
}
.noUi-active {
  background: darken($primary-dark,10%);
}

/**
mods
*******/
.noUi-horizontal{
  height:6px !important;
}
.noUi-handle{
  height: 24px !important;
}

/* Handle stripes;
 */
//.noUi-handle:before,
//.noUi-handle:after {
//  content: "";
//  display: block;
//  position: absolute;
//  height: 12px;
//  width: 1px;
//  background: #E8E7E6;
//  left: 14px;
//  top: 6px;
//}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */

[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* keep handles in */

.noUi-horizontal.noUi-extended {
  padding-right: 22px;
}
.noUi-horizontal.noUi-extended .noUi-handle {
  left: -1px;
}
.noUi-horizontal.noUi-extended .noUi-origin  {
  right: -22px;
}