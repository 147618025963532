.listing-side-bar{
  width: 100%;
  border-radius:0.5rem 0.5rem 0 0;
}
.listing-side-bar__address-box{
  border-radius: 0.5rem 0.5rem 0 0;
  background: $primary;
  padding: 1.5rem;
  color:white;
}
.listing-side-bar__title{
  color:white;
  font-family: $slab-font;
  font-weight: 200;
  font-size: 2rem;
  strong{
    color:white;
    font-weight: 700;
    font-size: 2.25rem;
  }
}
.listing-side-bar__transaction-type{
  display: inline-block;
}
// this one is the mobile layout price
.listing-side-bar__transaction-price{
  display: inline-block;
}
.listing-side-bar__price{
  font-size: 2rem;
}
.listing-side-bar__hits{
  background-color: $primary-dark !important;
  color:white !important;
  padding-left: 0!important;
  margin-top: 1rem;
  padding:1rem;
  font-size: 1rem !important;
  font-weight: bolder !important;
  .icon{
    width:2.5rem;
  }
  img{
    position:relative;
    top:2px;
    left:4px;
    width:2.5rem;
  }
}

@keyframes swing {
  20% {
    transform: rotate3d(1, 0 , 0, -30deg);
    //box-shadow: 0px 10px 0px rgba(black,0.1) inset;
  }

  30%{
    //box-shadow: 0px 0px 0px rgba(black,0.1) inset;
  }

  40% {
    transform: rotate3d(1, 0 , 0, 30deg);
    box-shadow: 0px 20px 0px rgba(black,0.1);
  }

  60% {
    transform: rotate3d(1, 0 , 0, -10deg);
    box-shadow: 0px 0px 0px rgba(black,0.1);
  }

  80% {
    transform: rotate3d(1, 0 , 0, 10deg);
    box-shadow: 0px 5px 0px rgba(black,0.1);
  }
  to {
    transform: rotate3d(1, 0 , 0, 0deg);
  }
}@keyframes swing {
  20% {
    transform: rotate3d(1, 0 , 0, -30deg);
    //box-shadow: 0px 10px 0px rgba(black,0.1) inset;
  }

  30%{
    //box-shadow: 0px 0px 0px rgba(black,0.1) inset;
  }

  40% {
    transform: rotate3d(1, 0 , 0, 30deg);
    box-shadow: 0px 20px 0px rgba(black,0.1);
  }

  60% {
    transform: rotate3d(1, 0 , 0, -10deg);
    box-shadow: 0px 0px 0px rgba(black,0.1);
  }

  80% {
    transform: rotate3d(1, 0 , 0, 10deg);
    box-shadow: 0px 5px 0px rgba(black,0.1);
  }
  to {
    transform: rotate3d(1, 0 , 0, 0deg);
  }
}

.listing-side-bar__visit-section{
  background: darken($primary,10%);
  padding:1.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  animation: swing 1.5s ease-in-out;
  transform-origin: 0% 0%;
}
.listing-side-bar__visit-section-title{
  font-weight: 700;
  color:white;
  font-size:1.5rem;
  text-align: left;
  @include tablet{
    font-size: 1rem;
  }
}
.listing-side-bar__visit-request-button{
  background:$primary;
  padding: 0.75rem 1em;
  color:white;
  font-weight: bolder;
  display: block;
  border-radius:3rem;
  text-shadow:$box-shadow;
  text-align: center;
  transition: all ease 0.5s;
  cursor: pointer;
  &:hover{
    background:#01dcd0;
  }

}
.listing-side-bar__bottom-nav{
  background: $danger;
  padding:0.75rem 1.5em;
}

.listing-side-bar__map-container{
  #map{
    height: 240px;
  }
}
#map-mobile{
  height:400px;
}
.mobile-map-modal{
  .modal-close{
    z-index:10;
  }
}

.immo-alert{
  animation: swing 1.5s ease-in-out;
  transform-origin: top center;
}

.listing-side-bar__sold-message{
  font-weight: 700;
  color:white;
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  {
  .listing-side-bar__address-box{
    position: relative;
  }
  .listing-side-bar__title{
    line-height:120%;
  }
  .listing-side-bar__title strong{
      font-size:0.9em;
  }
  .listing-side-bar__transaction-type{
    display: none;
  }
  .listing-side-bar__sub-prop-type{
    font-size: 0.6em;
  }
  .listing-side-bar__hits{
    position: absolute;
    bottom: -15px;
    left:calc(1.5em - 3px);
    z-index:9;
    border:3px solid $primary;
  }
  .listing-side-bar__map-container{
    height:200px;
  }

}