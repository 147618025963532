.listing-badge-info{
  background:white;
}
.listing-badge-icon{
  height:3rem;
  width:3rem;
  text-align: center;
  display: block;
  svg{
    fill:$primary-dark;
  }
}
.listing-detail-badge-box{
  background: white;
  box-shadow: $box-shadow;
  i {
    text-shadow: $box-shadow;

  }
  padding:1.25rem;
  .level-item:last-of-type
  {
    padding-left: 1.25rem;
  }
  @include mobile{
    i.fa{
      font-size: 2rem;
    }
    p{
      width: 200px;
    }
  }
}

// tabs
.tabs.is-boxed li.is-active a{
  background: $body-bg;
  border-color:$primary-dark;
  color:$primary-dark;
  font-weight: 500;
}
.tabs ul{
  border-bottom-color:$primary-dark;
}
.tabs.is-boxed a:hover{
  border-bottom-color:$primary;
}