@mixin anim ($delay, $duration, $animation) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}

// mixin
$retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)";

@mixin retina-background-image($file, $type) {
  background-image: url('/img/' + $file + '.' + $type);
  @media #{$retina} {
    background-image: url('/img/' + $file + '@2x.' + $type);
  }
}