$nav-height:72px;
// ---------
.site-nav__secondary{
  background: $siteGreen;
}
.main-site-nav{
  box-shadow: $box-shadow;
  border-top:3px $siteGreen solid;
}
// overide
@include desktop(){
  .main-site-nav .navbar-item {
    height: $nav-height;
  }
}

.main-site-nav .navbar-item img {
  max-height: 3rem;
}
.main-site-nav .navbar-burger{
  height: $nav-height;
  width: $nav-height;
}
.main-site-nav{
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  z-index: 10;
}
body{
  padding-top: $nav-height;
}

.main-site-nav__logo{
  display: block;
  width: 167px;
  height: 41px;
  background-size:100% !important;
  @include retina-background-image('logo','png');

}

.main-site-nav__info-tag{
  z-index: 9;
  position:fixed;
  right:0px;
  background: $siteRed !important;
  padding:1.2rem 1.5rem 1.2rem 0.5rem !important;
  border-radius: 0rem 0rem 0rem 0.5rem !important;
  color: white !important;
  font-family: $slab-font !important;
  font-weight: 700 !important;
  font-size:1rem;
  img{width: 40px;
    position: relative;
    top: 2px;
    left: 4px;
  }
  a{
    color:white;
    &:hover{
      opacity: 0.5;
    }
  }
  .icon{
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .icon:first-child{
    margin-left: 0rem;
  }
  svg{
    fill:white;
    width:20px;
  }
  @include mobile{
    border-radius: 0 0 0 0 !important;
    width: 100%;
    position: relative;
  }
}

@include touch {
  .navbar-item.has-dropdown {
    .navbar-dropdown {
       max-height: 0px;
      padding-top:0px;
      overflow: hidden;
      transition: max-height 1s ease, padding-top 1s ease;
    }
  }
  .navbar-item.has-dropdown:hover{
    .navbar-dropdown{
      max-height: 60vh;
    }
  }

  .navbar-end{
    background: #fafafa;
    padding-top: 1rem;
    height: calc(100vh - 72px);
    overflow:scroll;
  }
  .navbar-menu{
    padding: 0;
  }
}
@include mobile{
  @media screen and (orientation: landscape){
    .main-site-nav{
      position: absolute;
    }
  }
}
