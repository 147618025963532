.vendor-form{
  background: white;
  box-shadow: $box-shadow-smooth;
}
.vendor-form__left-tile{
  padding: 3rem;
  @include tablet-only{
    padding: 1.5rem;
  }
  @include mobile{
    padding:1.5rem;
  }
  background: $danger;
  color:white !important;
  strong{
    color:white !important;
  }
  .title{
    color: white !important;
  }
  .level{
    height: 100%;
    width:100%;
    min-height: 100px;
  }
  .level-item{
    width:100%;
  }
}
.vendor-form__content-tile{
  padding: 5rem;
  @include tablet-only{
    padding: 1.5rem;
  }
  @include mobile{
    padding:1.5rem;
  }
  .title{
    color:#363636 !important;
  }
}

#budget-slider{
  margin:1.5rem 0rem;
  .noUi-handle {
    border-radius: 15px;
    background: #01b6ac;
    cursor: default;
    border: 2px white solid;
  }
  &.noUi-target {
    background:#dbdbdb;
    border-radius: 4px;
  }
}