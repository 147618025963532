.award-box{
  margin-top: 75px;
  min-height: calc(100% - 75px);
  border-top: $primary 3px solid;
  border-radius:0px;
}
.award-item__image{
  border-radius: 150px;
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: auto;
  margin-top: calc(-1.5em - 75px);
  margin-bottom: 1.25em;
  box-shadow: $box-shadow-smooth;
  background: white;
}
.award-item__description{
  text-align: justify;
}