.listing-card:hover{
  transform:translateY(-10px);
  @include mobile()
  {
    transform: none;
  }
}

.listing-card__sold-banner {
  display: flex;
  position: absolute;
  top: 35%;
  h: 2rem;
  background:rgba($primary-dark,0.75);
  width: 100%;
  height: 30%;
  align-items: center;
  text-align: center;
  margin: auto;
  justify-content: center;
  color: white;
  font-family: $slab-font;
  font-weight: 700;
  text-transform: uppercase;
}

.listing-card__hits{
  background: $siteRed !important;
  color:white !important;
  position: absolute;
  bottom:0rem;
  right:0rem;
  padding:1rem;
  padding-left: 0 !important;
  border-radius: 0.5rem 0 0 0 !important;
  font-weight: bolder;
  font-size: 1rem !important;
  img{
    width: 50px;
    position:relative;
    top:2px;
    left:4px
  }
}
.listing-card__open-house{
  background: $primary-dark !important;
  position: absolute;
  bottom:0rem;
  left:0rem;
  border-radius:0 0.5rem 0 0 !important;
  padding:1.5rem;
  padding-right: 1rem !important;
  font-weight: bolder;
  color:white !important;
  @include tablet-only(){
    top:0rem;
    border-radius: 0 0 0.5rem 0 !important;
  }
  @include mobile(){
    top:0rem;
    border-radius: 0 0 0.5rem 0 !important;
  }
}
.listing-card__open-house-img{
  display: inline;
  width: 40px;
}
.listing-card__price-label{
  font-weight:400;
  font-size: 1.25rem;
}

.listing-card__description{
  height: 2.5rem;
  overflow: hidden;
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
}
.listing-card__prop-type-tag{
    position: relative;
    top:-2px;
    font-weight: 700 !important;
    font-family: $slab-font;
    font-size: 1rem;
}
.listing-card__address-label{
  font-weight: 700;
  margin-bottom:0.75rem;
}
.listing-card__stat{
  font-weight:700;
  margin-left:0.5rem;
}
.listing-card{
  border-radius: 0.5rem;
  transition: all 0.5s ease;
  height: 100%;
  overflow: hidden;
}
.listing-card.is-invalid-result{
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
  display: none;
}

 .listing-card svg {
    height: 2.5rem;
    fill:$primary-dark;
}