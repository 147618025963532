// testimonials
.testimonial{
  font-weight: 700;
  font-style: italic;
  max-width: 800px;
  margin:auto;
  color:#656565;
  transition: all 1s ease;
  backface-visibility: hidden;
}
.testimonial.flipped{
  transform: rotateY(180deg);
}
.testimonial__author{
  margin-top: 1.5rem;
  color:$primary-dark;
}
