$siteGreen:#01e9dc;
$siteRed:#c8102e;
$primary:$siteGreen;
$primary-dark:darken($siteGreen,10%);
$danger:$siteRed;
$box-shadow: 0 2px 0px rgba(black, 0.05);
$slab-font:'Roboto Slab', serif;
$base-font:BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$box-shadow-smooth:0px 0px 30px darken(whitesmoke,10%);
$body-bg:#fafafa;
$base-text-color:#4a4a4a;