/* Home */
.home-hero{
  position:relative;
  .level{height: 100%;}
  .level-item{width: 100%;}
  width: 100%;
  .columns{
    margin: 0;
  }
  .slick-track{
    display: flex;
    align-items: center;
  }
  .ndg-teaser{
    position: absolute;
    left: calc(50% - 80px);
    width: 160px;
    bottom:-50px;
    img {
      width: 120px;
      border-radius: 150px;
      border:5px white solid;
      display: block;
      margin: auto;
    }
    legend{
      font-family:$slab-font;
      background: white;
      color:$primary;
      font-weight: 500;
      box-shadow: $box-shadow;
      font-size: 0.75rem;
      padding: 0.25rem;
      position: relative;
      top: -36px;
      text-align: center;
    }
    @include mobile {
      display:none;
    }
  }
}
.home-hero__img{
  @include retina-background-image('serge-and-manon','png');
  @include mobile{
    @include retina-background-image('serge-and-manon_mobile','png');
  }
  background-size: cover;
  background-position:center;
  min-height: 500px;
  @include mobile(){
    height: 300px;
    min-height: auto;
  }
  @media all and (max-width: 420px) {
    height: 200px;
  }
}
.home-hero .home-hero__promo{
  background: $primary;
  padding:3rem !important;
  display: flex;
  align-items: center;
  @include mobile(){
    padding: 1.25rem !important;
  }
}
.home-hero__title{
  font-size: 3.5rem;
  color:white !important;
  margin-bottom:1.5rem !important;
  @include mobile()
  {
    font-size: 2rem;
  }
}
.home-hero__lead{
  margin-bottom: 1.5rem;
  color: white;
}
.home-hero__cta{
  background: $primary-dark;
  border-color: $primary-dark;
  color:white;
  transition:all ease 0.25s;
  &:hover{
    background: $primary-dark !important;
    border-color: $primary-dark !important;
    color:white !important;
    opacity: 0.6 !important;
    box-shadow: 0px 0px 10px;
  }
}

// new propery teaser
.video-teaser {
  &:hover{
    margin-top: -1rem;
  }
  transition: all ease 0.5s;
  margin-top:1.5rem;
  img {
    width: 240px;
    border-radius: 150px;
    border:10px white solid;
    display: block;
    margin: auto;
    box-shadow: $box-shadow;
  }
  a {
    position:relative;
  }
  legend{
    font-family:$slab-font;
    background: white;
    color:$primary;
    font-weight: 500;
    box-shadow: $box-shadow;
    font-size: 1rem;
    padding: 0.5rem;
    position: absolute;
    bottom: 35px;
    text-align: center;
    width: 100%;
  }
}

// new property component

.new-properties{
  padding: 3rem 0rem;
  .columns{
    margin: 0;
  }
}
.site-section-header{
  margin-bottom: 2.5rem !important;
}
.new-property{
  box-shadow: $box-shadow-smooth;
  background: white;
  max-width: 700px;
  max-height:324px;
  @include mobile{
    max-height: 1000px;
  }
  transition: all ease 0.5s;
}
.new-property:hover{
  margin-top: -1rem;
}
.new-property > a {
  color:inherit;
}
.js-new-listings .slick-list {
  padding: 1.25rem 50px !important;
  margin-top: -1.25rem !important;
}
.new-property__img{
  background-size: cover;
  background-position: center center;
  height: 300px;
  width: 300px;
  position:relative;
}
.new-property-title{
  color:$danger !important;
  font-size: 1.5rem;
  margin-bottom: 1.5rem !important;
}
.new-property__level{
  position: relative;
}
.new-property__content{
  width:300px;
  height: 300px;
  .level,.level-item{
    height: 100%;
    width: 100%;
  }
}
.new-property__text{
  padding: 3rem;
  max-height: 324px;
  @include mobile(){
    padding: 1.25rem;
  }
}
.new-property__description{
  max-height:120px;
}
.new-property__stats{
  color:$primary-dark !important;
  font-family: $slab-font;
}
.new-property .icon svg{
  height:100%;
  fill: $primary-dark;
  position: relative;
  top:0.35rem;
}
.new-property .icon {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.new-property .icon:first-child{
  margin-left: 0;
}
.new-property__open-house{
  background: $primary-dark !important;
  position: absolute;
  top: 0rem;
  left: 20px;
  border-radius: 0 0 0.5rem 0.5rem !important;
  padding: 1.5rem;
  padding-right: 1rem !important;
  font-weight: bolder;
  color: white !important;
  width: calc(100% - 40px);
}
.new-property__open-house-img{
  display: inline;
  width: 35px;
}

@include mobile(){

  .new-property__open-house{
    border-radius:0.5rem 0.5rem 0.5rem 0.5rem !important;
    margin-top:-3rem;
    width: calc(100% - 40px);
  }
  .new-property__img{
    height: 200px;
    min-height: auto;
  }
  .new-property__content{
    padding: 1.5rem;
    height: auto;
  }
}