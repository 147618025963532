.listing-search{
  background: $primary;
  padding:1.25rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
}
.listing-search__top-icon{
  margin:-4rem auto 0;
  background: $primary-dark;
  border:$body-bg 3px solid;
  border-radius: 4rem;
  width:5rem;
  height:5rem;
  .level{
    height: 5rem;
    margin:auto;
  }
  svg{
    fill: white;
    width: 3.5rem;
    margin-top: 0.3rem;
  }
}
.listing-search__price-label{
  font-family: $slab-font;
  color:white;
  font-weight: 500;
  @include desktop(){
    font-size: 1.25rem !important;
  }
  @include tablet(){
    font-size: 1rem;
  }
  @include mobile(){
    font-size: 1rem;
  }

}
.listing-search__value-badge{
  background-color: $primary-dark !important;
  color:white !important;
  font-weight: 500;
  border:0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  min-width: 100px;
  text-align: left;
  svg{
    height: 1.5rem;
    fill:white;
    width: 5rem;
  }
}