.sold-card__wrapper{
  position: relative;
  margin-bottom: 1rem;
  @include mobile{
    .sold-card__content{
      text-align: center;
    }
  }
}
.sold-card__wrapper:nth-child(even){
  .sold-card{
    flex-direction: row-reverse;
    .sold-card__content{
      text-align: right;
      @include mobile{
        text-align: center;
      }
    }
  }
  .sold-card__banner{
    right:0px;
    transform: rotate(5deg);
  }
}
.sold-card{
  box-shadow: $box-shadow-smooth;
}
.sold-card__figure{
  min-height: 320px;
  height: 100%;
  background-size: cover;
  background-position: center center;
  @include mobile{
    height: 35vh;
  }
}
.sold-card__content{
  background: white;
  padding:3rem;
  @include mobile{
    padding:1rem;
  }
}
.sold-card__title{
  color:$danger !important;
}
.sold-card__description-container{
  max-height: 160px;
  margin-bottom: 16px;
}
.sold-card__banner-wrapper{
  position: relative;
  text-align: center;
}
.sold-card__banner{
  background: $primary-dark;
  color:white;
  display: inline-block;
  padding:1rem;
  position: absolute;
  margin: auto;
  transform: rotate(-5deg);
  border-radius:3px;
  box-shadow: $box-shadow-smooth;
  font-weight: 700;
  @include mobile{
    font-size: 0.75rem;
  }
}

