.hood-promo{
  margin-bottom: 1.5rem;
}

.hood-promo__photo{
  background-size: cover;
  background-position: center;
}

.hood-promo__content{
  padding:5rem;
  background: $danger;
  color:white;
  .title,a{
    color:white !important;
  }
  @include mobile(){
    .title{
      font-size: 2rem;
    }
    padding: 1.25rem;
  }
}
.hood-promo__more-info{
  margin-top: 1.5rem;
}
.hood-promo__more-info a{
  text-decoration: underline;
  &:hover{
    opacity:0.5;
  }
}

.hood-page{
  .title { font-weight: 100}
}


.iq-promo{
  background:$primary-dark;
  margin-bottom: 1.5rem;
  color:white;
  .title,.subtitle{
    color:white;
  }
  a:not(.button){
    color: white;
    text-decoration: underline;
    &:hover{
      opacity: 0.9;
    }
  }
}