.fullscreen-container{
  background: $primary;
  width: 100%;
  height: 100%;
  * {
    transition:all ease 0.5s;
  }
}
.fullsreen-main{
  height: 100%;
  @include mobile(){
    height: auto;
  }
  padding: 1rem;
}
.fullsreen-main{
  background: $primary;
}
.fullscreen-main__infos{
  max-width:600px;
  margin:1.5rem auto 0rem;
  position:relative;
  margin-top: -80px;
  z-index:4;
  background: rgba(255,255,255,0.7);
  @include mobile(){
    margin-top: 15px;
    background: white;
  }
}
.fullscreen-close{
  z-index: 3
}

// main gallery slider
.main-gallery {
  box-shadow: $box-shadow-smooth;
  border:white 5px solid;
  max-width: 1440px;
  max-height: 90vh;
  //max-height: 940px;
  //
  @media screen and (max-width: 1440px) {
    min-height: 400px;
  }

  @include mobile(){
    margin-top:36px;
    min-height: 150px;
    @media (orientation: landscape) {
      margin-top:0;
    }
  }
  .swiper-slide-next,
  .swiper-slide-prev{
    opacity:0.8;
  }
}

.main-gallery .swiper-slide img {
  display:block;
}

// thumbnails slider
.thumb-gallery{
  max-width: 1440px;
  margin-top: 1rem;
  padding:0.5rem;
  @include mobile(){
    height: 20vh;
    margin-top: 0;
  }
}
.thumb-gallery .swiper-slide img{
  width: 100%;
  border-radius: 100%;
  border:white 4px solid;
  box-shadow: $box-shadow-smooth;
  transition: all 0.5s ease;
  &:hover{
    transform: translate(0,-5px);
  }
}

.thumb-gallery .swiper-slide-thumb-active img {
  animation:levitate 1s infinite ease;
}

.js-full-screen-photo-info-container{
  opacity: 0;
  transition: all ease 0.5s;
    &.active {
      opacity: 1;
    }
}
.fullscreen-nav{
  opacity: 0.5;
  &:hover{
    opacity:1;
  }
}