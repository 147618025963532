#contact-board__photo{
  min-height:300px;
  background: url('/img/team/serge_and_manon.jpg');
  background-size:cover;
  background-position: center;
}
.contact-board{
  box-shadow: $box-shadow-smooth;
}
.contact-board__infos{
  background: $primary;
  padding:1.5em;
  color: white;
  p,a,strong{
    color:white;
    text-shadow: 1px 1px 0px rgba(darken($primary,5%),0.5);
  }
}
.contact-board__infos-phone{
  font-size:2em;
}
.contact-board__form-container{
  background: white;
  .title{
    color:#363636 !important;
  }
  @include desktop{
    padding:5em;
  }
  padding:1.5em;
}
.contact-board__infos .subtitle{
  color: white;
  text-transform: uppercase;
  text-align: left;
  font-size: 1.25em;
}
.contact-board__infos .level{
  height: 100%;
  min-height: 80px;
  width:100%;
}

// royal lepage board
.royal-lepage-board{
  box-shadow: $box-shadow-smooth;
}
.royal-lepage-board__logo-tile{
  min-height: 150px;
  background:#DB2F34;
  .level{
    height: 100%;
    width: 100%;
    min-height: 150px;
  }
  img{
    max-height: 80px;
    margin:auto;
  }
}
.royal-lepage-board__content-tile{
  @extend .contact-board__form-container;
  padding: 3em 5em;
  @include mobile(){
    padding: 1.25rem;
  }
}