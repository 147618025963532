.js-listing-photo-album{
  //height: 420px;
  max-height: 450px;
  img{
    max-height: 450px;
    width: auto !important;
  }
  .slick-slide {
    height: 450px;
    img {
      max-height: 450px;
    }
  }
  @include tablet{
    height: 350px;
    max-height: 350px;
    .slick-slide {
      height: 350px;
      img {
        max-height: 350px;
      }
    }
  }
  @include mobile{
    height: 350px;
    max-height: 350px;
    .slick-slide {
      height: 350px;
      img {
        max-height: 350px;
      }
    }
  }
  @media (max-width: 420px){
    height: 200px;
    max-height: 200px;
    .slick-slide {
      height: 200px;
      img {
        max-height: 200px;
      }
    }
  }

  overflow: hidden;
  .slick-slide{
    transition: all ease 0.5s;
    opacity: 0.2;
    &.slick-current{
      opacity: 1;
    }
  }
}

.listing-slideshow-nav{
  background: white;
  padding: 0.75rem;
  box-shadow: $box-shadow;
  margin-bottom: 1.5rem;
}

.listingPhotoArrows{
  display: flex;
  justify-content: space-between;
  height: 0;
  overflow: visible;
}
.listingPhotoArrows a {
  margin: 0px 1rem;
  height:3rem;
  width:3rem;
  position: relative;
  top:calc( -175px - 0.5rem);
  background: white;
  border-radius: 3rem;
  box-shadow:$box-shadow;
  .icon{
    display: flex;
    margin: 0;
    height: 100%;
    width: 100%;
  }
}
