.portfolio-lead{
  box-shadow: $box-shadow-smooth;
}
.portfolio-index__lead{
  min-height: 140px;
  color:white;
}
.portfolio-lead__total{
  background: $danger;
}
.portfolio-lead__total .level {
  height: 400px;
  width: 100%;
  @include mobile(){
    height: 120px;
  }
}
.portfolio-lead__total {
  .title{ color:white; }
  .subtitle{ color:white; }
}
.portfolio-box{
  padding:1.5rem;
}
.portfolio-box .title {
  font-family: $base-font;
  font-weight:700;
  color:$danger;
}

#portfolio-promo a{
  p{
    color:$primary-dark;
  }
  &:hover{
    p{
      color: $primary;
    }
  }
}