.listing-mobile-info{
  background: $siteGreen;
  padding: 1.25rem;
  width: 100%;
  z-index: 9;
  position: fixed;
  bottom:0px;
}
.listing-mobile-info p {
  color:white;
  padding:0px 48px;
}
.listing-mobile-info__prop_type{
  color: white;
  font-family: $slab-font;
}
.listing-mobile-info__address{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-map-button{
  position: absolute;
  bottom:1rem;
  right:1rem;
  > i {
    z-index: 10;
    padding: 10px;
    border-radius: 50px;
    box-shadow: $box-shadow;
    background: $primary-dark;
      svg {
        width: 40px;
        height: 40px;
        fill: white;
      }
    }
  }

@media (orientation: landscape) {
  .listing-mobile-info{
    position: relative;
  }
  .listing-mobile-info__visit-btn{
    position: fixed;
    bottom:0px;
    width: 100%;
  }
}