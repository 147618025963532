.listing-summary__summary-title{
  font-weight: 100;
}
.listing-summary__description  .title {
  color:$primary-dark;
}

.open-house-notification{
  background: $primary-dark !important;
  color:white;
}
.open-house-notification__image{
  height: 60px;
}

.listing-detail__summary-badge{
  //border: $primary-dark solid 1px !important;
  color:$primary-dark !important;
  margin-bottom:1.5rem;
   strong {
    color:$primary-dark;
  }
}
.summary-badge-item{
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  &:first-child{
    margin-left: 0rem;
  }
  &:last-child{
    margin-right: 0rem;
  }
  .icon{
    margin-right:0.5rem;
    position: relative;
    top:0.5rem;
  }
  .icon > svg {
    fill:$primary-dark;
    height: 100%;
  }
}

.listing-detail-badge-tile{
  background: white;
  box-shadow: $box-shadow;
  margin-bottom:1.5rem;
  @include mobile{
    text-align: center;
  }
}
.icon-tile{
  .level{
    height: 100%;
    svg{
      fill:$primary-dark;
    }
  }
  @include tablet{
    max-width: 6rem;
  }
  @include mobile{
    .tile.is-child{
      height:100%;
    }
    background: $primary-dark;
    width: 6rem;
    height: 6rem;
    position: relative;
    left: calc(50% - 3rem);
    top: -3rem;
    border-radius: 10rem;
    margin-bottom: -3rem;
    margin-top: 4.5rem;
    .level svg{fill:white}
  }

}