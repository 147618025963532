// hero section
#about-hero {
  background: $primary;
}

#about-hero .title,
#about-hero .subtitle {
  color: white;
  text-shadow: $box-shadow;
}

// BIG PROFIL
.big-profil {
  background: white;
  box-shadow: $box-shadow-smooth;
  max-width: 1200px;
  margin: auto;
}

.big-profil #serge-profile__picture {
  background: url('/img/team/serge.jpg');
  background-size: cover;
  background-position: center;
  margin-bottom: 0rem !important;
  min-height: 250px;
}

.big-profil #manon-profile__picture {
  background: url('/img/team/manon.jpg');
  background-size: cover;
  background-position: center;
  margin-bottom: 0rem !important;
  min-height: 250px;
}

// team boxes
.big-profil__person-name-tile {
  background: $primary;
  color: white !important;
  min-height: 100px;
}

.big-profil__person-name-tile--red {
  @extend .big-profil__person-name-tile;
  background: $danger;
}

.big-profil__name-tile-level {
  height: 100%;
  min-height: 100px;
}

.big-profil__person-name {
  color: white;
  margin-bottom: 0rem;
  font-family: $slab-font;
  font-weight: 700;
  font-size: 2rem;
  @include mobile() {
    font-size: 1.5rem;
  }
}

.big-profil__person-title {
  text-transform: uppercase;
  font-weight: 500;
}

.big-profil__content-pad {
  background: white;
  padding: 5em;
  @include mobile() {
    padding: 1.25rem;
  }
}

.team-box {
  background: whitesmoke;
  padding: 2em;
}

.team-box header {
  margin-bottom: .75em;
}

.team-box__image {
  width: 150px;
  height: 150px;
  margin: 0.75em;
  overflow: hidden;
  border-radius: 150px;
  box-shadow: $box-shadow-smooth;
}

.team-box img {
  border-radius: 150px;
}

h5.team-box__member-name {
  color: $danger;
  font-weight: 700;
}

.team-box__member-title {
  font-weight: 100;
  text-transform: uppercase;
}

@include mobile {
  .team-box {
    margin-top: calc(75px + 2em);
  }
  .team-box header {
    text-align: center;
  }
  .team-box__image {
    margin: auto;
    margin-top: calc(-75px - 2em);
  }
}

// AWARDS
.award-section__button {
  background: darken($danger, 10%) !important;
  animation: levitate 1.5s infinite ease-in-out;
}

@keyframes levitate {
  0% {
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.3);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 10px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-5px);
  }
  100% {
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.3);
    transform: translatey(0px);
  }
}


